<template>
  <div class="undress overflow-y-auto">
    <!-- <PullRefresh  :params="moreParams"v-if="!this.loading" @onLoad="onLoad" @onRefresh="onRefresh"> -->
    <div class="modArea flex-center-between">
      <div
        class="modBox"
        v-for="item in lists"
        @click="toPage(item)"
        :key="item.id"
      >
        <div class="modVideoBox">
          <ImgDecypt :src="item.cover" class="modCover">
            <div class="hot" v-if="item.hotMark">{{ item.hotMark }}</div>
            <div class="count" v-if="item.hotValue">
              <svg-icon iconClass="fire"></svg-icon>
              {{ item.hotValue }}
            </div>
          </ImgDecypt>
          <p class="ellipsis">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <!-- </PullRefresh> -->
    <!-- <Loading class="loading" v-else /> -->
    <div class="sizeBox"></div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { uploadImg } from "@/api/app";
import { generateImg, getAiMod } from "@/api/mine";
import { mapGetters } from "vuex";
import ImgDecypt from "@/components/ImgDecypt";

export default {
  name: "changeFaceImage",
  components: {
    ImgDecypt,
  },
  props: {
    lists: [],
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      list: [], // 换脸模版
      loading: true,
    };
  },
  created() {},
  mounted() {},
  methods: {
    toPage(item) {
      localStorage.setItem("deatilData", JSON.stringify(item));
      this.$router.push({
        path: "/changeDetail",
        query: { type: "image" },
      });
    },
    /**
     * 获取模板列表
     */
    async getList() {
      try {
        let res = await this.$Api(getAiMod);
        this.list = res.aiChangeFaceMod || [];
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-image__img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.undress {
  margin-top: 15px;
  width: 100%;
  .modArea {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 6px;
    padding: 0 10px;
    box-sizing: border-box;
    .modBox {
      height: 177;
      width: 111px;
      background: #ffffff;
      border-radius: 8px;
      .modVideoBox {
        border-radius: 4px;
        overflow: hidden;
        transform: translateZ(0px);
      }
      .modCover {
        height: 148px;
        width: 100%;
        position: relative;
        .hot {
          position: absolute;
          left: 0;
          top: 0;
          color: #ffffff;
          background: #fc4162;
          border-radius: 8px 0 8px 0;
          font-size: 10px;
          padding: 2px 6px;
          line-height: 16px;
          text-align: center;
          width: 33px;
          height: 16px;
          font-family: "PingFang SC";
          letter-spacing: 1px;
        }
        .count {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          left: 0;
          color: #ffffff;
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.5)
          );
          padding-right: 10px;
          box-sizing: border-box;
          svg {
            width: 14px;
            height: 14px;
            margin: 0 5px 0 0;
          }
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      p {
        margin: 8px 4px;
        height: 20px;
        width: 100%;
        overflow: hidden;
      }
    }
  }
  .sizeBox {
    height: 140px;
  }
}
</style>

<template>
  <div class="aiUndress">
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">AI科技</div>
      <div class="rightBtn" @click="$router.push('/aiRecord')">记录</div>
    </div>
    <van-tabs
      v-model="active"
      title-active-color="#333333"
      title-inactive-color="#999999"
      color="#999999"
      background="rgba(0,0,0,0)"
      animated
    >
      <van-tab>
        <template #title><div class="tabTitle" :class="{ activeTab: active == 0 }">AI脱衣</div></template>
        <div class="content">
          <undress :lists="undresslList" />
        </div>
      </van-tab>
      <van-tab>
        <template #title><div class="tabTitle" :class="{ activeTab: active == 1 }">视频换脸</div></template>
        <div class="content">
          <changeFaceVideo :lists="changeFaceVideoList" />
        </div>
      </van-tab>
      <van-tab>
        <template #title><div class="tabTitle" :class="{ activeTab: active == 2 }">图片换脸</div></template>
        <div class="content">
          <changeFaceImage :lists="changeFaceImageList" />
        </div>
      </van-tab>
    </van-tabs>
    <!--     
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">AI脱衣</div>
      <div class="rightBtn" @click="$router.push('/aiRecord')">生成记录</div>
    </div>
    <div class="main">
      <div class="cover" v-if="coverImg">
        <ImgDecypt :src="coverImg" :key="coverImg" class="cover" />
      </div>
      <div @click.stop v-else>
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          :preview-image="false"
        >
          <div class="uploadBox"></div>
        </van-uploader>
      </div>
      <div class="precautions">注意事项：</div>
      <p>1.照片只含一名人物</p>
      <p>2.照片不能过暗</p>
      <p>3.照片尽量清晰</p>
      <p>4.不支持多人照片禁止未成年人照片</p>
      <div class="generateBox"></div>
      <div class="bottom">
        <div class="priceBox">
          <div class="coinOnce">免费次数:{{ freeCount }}</div>
          <div class="coinOnce">{{ aiUndressPrice }}金豆一次</div>
        </div>
        <div class="submit" @click="getGenerateImg">提交</div>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { Toast } from 'vant';
  import { uploadImg } from '@/api/app';
  import { generateImg, getAiMod } from '@/api/mine';
  import { mapGetters } from 'vuex';
  import ImgDecypt from '@/components/ImgDecypt';
  import Undress from './undress.vue';
  import changeFaceVideo from './changeFaceVideo.vue';
  import changeFaceImage from './changeFaceImage.vue';

  export default {
    name: 'aiUndress2',
    components: {
      // ImgDecypt,
      Undress,
      changeFaceVideo,
      changeFaceImage,
    },
    computed: {
      ...mapGetters({
        walletInfo: 'walletInfo',
        userInfo: 'userInfo',
      }),
      balance() {
        return (this.walletInfo?.amount || 0).toFixed(1);
      },
    },
    data() {
      return {
        // aiUndressPrice: 0,
        // fileList: [],
        // coverImg: "",
        // generateCoverImg: "",
        // isVipbuyShow: false,
        // freeCount: "",

        active: 0,
        showPlayVideo: false,
        sourceURL: '', // 换脸后的视频链接
        gold: 0,
        undresslList: [
          {
            cover: 'cf230705/image/17o/xz/2f6/he/7f669aed7fdcf1355c608c291c33bcd1.jpg',
            id: '6539136b28776657a00e4057',
          },
          {
            cover: 'cf230705/image/256/24e/2c2/12g/f3028367619ab48837c6f14b6fc7f428.jpg',
            id: '6539136b28776657a00e4058',
          },
          {
            cover: 'cf230705/image/2ey/93/1dt/2ln/7a8225dd61485ad7ea78e1b3fafe3046.jpg',
            id: '6539136b28776657a00e405a',
          },
          {
            cover: 'cf230705/image/2pc/2rt/cn/13l/fff05c8508ce19e3d14d8b7298509a59.jpg',
            id: '6539136b28776657a00e405b',
          },
          {
            cover: 'cf230705/image/n6/1ps/25k/35e/0109cd941eb05dbe847f248614362c01.jpg',
            id: '6539136b28776657a00e4059',
          },
        ],
        changeFaceVideoList: [],
        changeFaceImageList: [],
        isGoldCoinPurchase: false,
        loading: true, // loading
        isNoData: false, // 暂无数据
        couponId: '',
      };
    },
    created() {
      this.aiUndressPrice = this.$store.getters.appConfig.aiUndressPrice || 0;
      this.freeCount = this.userInfo.aiUndressCount || 0;
      this.active = Number(this.$route.query.active || 0);
    },
    activated() {
      this.active = Number(this.$route.query.active || 0);
    },
    mounted() {
      this.getList();
    },
    methods: {
      async getList() {
        try {
          let res = await this.$Api(getAiMod);
          if (res.code === 200) {
            console.log(res.data);
            // this.undresslList = res.data.aiUndressMod || [];
            this.changeFaceVideoList = res.data.aiChangeFaceVideoMod || [];
            this.changeFaceImageList = res.data.aiChangeFaceMod || [];
          }
        } catch (e) {
          console.log(e);
        }
      },
      // 获取优惠券列表
      async getAiCoupon() {
        let res = await this.$Api.getAibackpack({
          page: 1,
          limit: 50,
          status: 2,
        });
        this.list = res.data || [];
        if (this.list.length <= 0) {
          this.isNoData = true;
        }
      },
      // 图片上传
      async afterRead(file) {
        if (file.length) {
          file.map(async (item, i) => {
            let newFile = await this.compress(item.file);
            item.file = newFile;
            await this.uplpadImg(item);
          });
        } else {
          let newFile = await this.compress(file.file);
          file.file = newFile;
          this.uplpadImg(file);
        }
      },
      //图片上传
      async uplpadImg(fileInfo) {
        let req = new FormData();
        req.append('upload', fileInfo.file);
        fileInfo.status = 'uploading';
        fileInfo.message = '上传中...';
        try {
          let ret = await this.$Api(uploadImg, req);
          if (ret.code == 200) {
            fileInfo.status = '';
            fileInfo.message = '';
            this.coverImg = ret.data.filePath[0];
            return;
          }
          fileInfo.status = 'failed';
          fileInfo.message = '上传失败';
        } catch (e) {
          fileInfo.status = 'failed';
          fileInfo.message = '上传失败';
        }
      },
      // 压缩图片
      compress(file) {
        let selt = this;
        if (!file || !window.FileReader) return;
        if (/^image/.test(file.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          return new Promise((resolve, reject) => {
            reader.onloadend = function () {
              let result = reader.result;
              let img = new Image();
              img.src = result;
              if (result.length <= 200 * 1024) {
                let blob = selt.convertBase64ToBlob(result);
                let files = new File([blob], file.name, { type: blob.type });
                resolve(files);
                return;
              }
              img.onload = function () {
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                let width = img.width;
                let height = img.height;
                canvas.width = width;
                canvas.height = height;
                // 铺底色
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, width, height);
                //进行最小压缩
                let ndata = canvas.toDataURL('image/jpeg', 0.3);
                let blob = selt.convertBase64ToBlob(ndata);
                let files = new File([blob], file.name, { type: blob.type });
                resolve(files);
              };
            };
          });
        }
      },
      //将base64转换为文件对象
      convertBase64ToBlob(base64) {
        var base64Arr = base64.split(',');
        var imgtype = '';
        var base64String = '';
        if (base64Arr.length > 1) {
          //如果是图片base64，去掉头信息
          base64String = base64Arr[1];
          imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'));
        }
        // 将base64解码
        var bytes = atob(base64String);
        //var bytes = base64;
        var bytesCode = new ArrayBuffer(bytes.length);
        // 转换为类型化数组
        var byteArray = new Uint8Array(bytesCode);

        // 将base64转换为ascii码
        for (var i = 0; i < bytes.length; i++) {
          byteArray[i] = bytes.charCodeAt(i);
        }
        // 生成Blob对象（文件对象）
        return new Blob([bytesCode], { type: imgtype });
      },
      // 获取脱衣后的图片
      async getGenerateImg() {
        if (!this.userInfo.isVip) {
          this.$bus.$emit('vipPopup', {
            this: 1,
            closeBtn: () => {
              this.$bus.$emit('closeVipPopup');
            },
          });
          return;
        }
        if (!this.coverImg) return Toast('请先上传一张图片');
        if (this.freeCount <= 0 && Number(this.aiUndressPrice) > this.balance) return Toast('免费次数与账户余额不足');
        let req = {
          originPic: this.coverImg,
          coin: Number(this.aiUndressPrice),
        };
        let res = await this.$Api(generateImg, req);
        if (res && res.code == 200) {
          Toast('上传成功，等待审核');
          this.coverImg = '';
          if (this.freeCount > 0) {
            this.freeCount--;
          }
        } else {
          Toast(res.tip || '上传失败');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  /deep/ .van-tabs__wrap {
    width: 343px;
    height: 33px;
    background: #fff;
    margin: 20px auto 4px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #f0f0f3;
    justify-content: space-between;
    // padding: 0 3.8px;
    // box-sizing: border-box;
    // display: flex;
    // align-items: center;
  }

  /deep/ .van-tabs__line {
    display: none;
  }
  /deep/ .van-tabs__nav {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }

  /deep/ .van-tab {
    padding: 0;
    // width: 65px;
    // margin: 0 10px;
  }
  .tabTitle {
    width: 105px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Dream Han Sans TC';
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 18px;
  }
  .activeTab {
    background: url('../../../assets/png/tabBg.png');
    background-size: 100% 100%;
  }
  .content {
    // background: #f1f1f1;
    overflow: auto;
    height: calc(100vh - 44px);
  }
  .aiUndress {
    height: 100%;
    .navBar {
      height: 44px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      // border-bottom: 1px solid #e6e6e6;
      .navArrow {
        width: 17px;
        height: 17px;
        margin-right: 26px;
      }
      .navTitle {
        flex: 2;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .rightBtn {
        color: #666666;
      }
    }
    .main {
      padding: 11px;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 44px);
      -webkit-overflow-scrolling: touch;
      .uploadBox {
        height: 178px;
        width: 352px;
        border-radius: 6px;
        background: url('~@/assets/png/aiUploadBg.png') no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
      }
      .cover {
        height: 178px;
        width: 352px;
        border-radius: 6px;
        overflow: hidden;
        margin: 0 auto;
      }
      .precautions {
        font-size: 16px;
        color: rgb(102, 102, 102);
        margin-top: 18px;
      }
      p {
        font-size: 14px;
        color: rgb(162, 159, 159);
        line-height: 20px;
      }
      .generateBox {
        height: 166px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 18px auto 0;
        background: url('./../../../assets/png/caseDiagram.png') no-repeat;
        background-size: 100% 100%;
      }
      .bottom {
        margin: 60px 0;
        padding: 0 20px;
        .priceBox {
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 0 25px;
          color: rgb(54, 54, 54);
        }
        .submit {
          height: 40px;
          background-color: #7145e7;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          color: white;
          border-radius: 40px;
          margin-top: 16px;
        }
      }
    }
    /deep/ .cover img {
      object-fit: unset !important;
    }
    /deep/ .van-image__img {
      width: unset;
    }
    /deep/ .van-uploader {
      width: 100%;
      .van-uploader__wrapper {
        width: 100%;
        .van-uploader__input-wrapper {
          width: 100%;
        }
      }
    }
  }
</style>

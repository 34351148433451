<template>
  <div class="undress">
    <div class="undress_top">
      <div class="cover" v-if="coverImg">
        <ImgDecypt :src="coverImg" :key="coverImg" class="cover" />
      </div>
      <div @click.stop v-else>
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          :preview-image="false"
        >
          <div class="uploadBox flex-center-center">
            <img src="@/assets/png/aiUploadBg.png" alt="" />
            添加图片
          </div>
        </van-uploader>
      </div>
      <div class="precautionsBox">
        <h2>注意事项:</h2>
        <p>1.照片只含一名人物</p>
        <p>2.照片不能过暗</p>
        <p>3.照片尽量清晰</p>
        <p>4.不支持多人照片禁止未成年人照片</p>
        <p>5.上传图片需间隔60s</p>
      </div>
    </div>

    <div class="aiImg">
      <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
        <van-swipe-item
          v-for="(item, index) in lists"
          :key="index"
          @click="goAdv(item)"
        >
          <ImgDecypt :src="item.cover" :key="item.cover" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="bottom">
      <p>
        你当前{{
          walletInfo.aiUndressFreeTimes  > 0 ? "有" + walletInfo.aiUndressFreeTimes  + "次" : "没有"
        }}免费体验次数
      </p>
      <p>
        处理一张照片的费用是
        <span>{{ this.aiUndressPrice || 0 }}金豆</span>
      </p>
    </div>
    <div class="submit flex-center-center" @click="onSubmit">
      确定提交
      <!-- <span>({{ this.totalPrice || 0 }}金币)</span> -->
    </div>
    <div class="sizeBox"></div>
  </div>
</template>
<script>
import { Toast, Uploader } from "vant";
import { uploadImg } from "@/api/app";
import { generateImg, getAiMod } from "@/api/mine";
import { mapGetters } from "vuex";
import ImgDecypt from "@/components/ImgDecypt";

export default {
  name: "undress",
  components: {
    ImgDecypt,
    [Uploader.name]: Uploader,
  },
  props: {
    lists: [],
  },
  computed: {
    ...mapGetters({
      walletInfo: "walletInfo",
      userInfo: "userInfo",
    }),
    balance() {
      return (this.walletInfo?.amount || 0).toFixed(1);
    },
  },
  data() {
    return {
      coverImg: "",
      generateCoverImg: "",

      uploadImages: [],
      fileList: [],
      timer: "",
      aiUndressPrice: 0, // ai脱衣的售价
      totalPrice: 0, // ai脱衣总售价
      fileSize: 500 * 1024,
      freeCount: 0,
      list: [],
      loading: true,
    };
  },
  created() {
    this.aiUndressPrice = this.$store.getters.appConfig.aiUndressPrice || 0;
    this.freeCount = this.userInfo.aiUndressCount || 0;
  },
  mounted() {},
  methods: {
    beforeRead(file) {
      if (file.size > this.fileSize) {
        Toast("文件不可超过500kb");
        return false;
      }
      return true;
    },
    // 获取优惠券列表
    async getAiCoupon() {
      let res = await this.$Api.getAibackpack({
        page: 1,
        limit: 50,
        status: 2,
      });
      this.loading = false;
      this.list = res.data || [];
      if (this.list.length <= 0) {
        this.isNoData = true;
      }
    },
    // 图片上传
    async afterRead(file) {
      if (file.length) {
        file.map(async (item, i) => {
          let newFile = await this.compress(item.file);
          item.file = newFile;
          await this.uplpadImg(item);
        });
      } else {
        let newFile = await this.compress(file.file);
        file.file = newFile;
        this.uplpadImg(file);
      }
    },
    //图片上传
    async uplpadImg(fileInfo) {
      let req = new FormData();
      req.append("upload", fileInfo.file);
      fileInfo.status = "uploading";
      fileInfo.message = "上传中...";
      try {
        let ret = await this.$Api(uploadImg, req);
        if (ret.code == 200) {
          fileInfo.status = "";
          fileInfo.message = "";
          this.coverImg = ret.data.filePath[0];
          return;
        }
        fileInfo.status = "failed";
        fileInfo.message = "上传失败";
      } catch (e) {
        fileInfo.status = "failed";
        fileInfo.message = "上传失败";
      }
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            if (result.length <= 200 * 1024) {
              let blob = selt.convertBase64ToBlob(result);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
              return;
            }
            img.onload = function () {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL("image/jpeg", 0.3);
              let blob = selt.convertBase64ToBlob(ndata);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
            };
          };
        });
      }
    },
    //将base64转换为文件对象
    convertBase64ToBlob(base64) {
      var base64Arr = base64.split(",");
      var imgtype = "";
      var base64String = "";
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(
          base64Arr[0].indexOf(":") + 1,
          base64Arr[0].indexOf(";")
        );
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype });
    },
    // 获取脱衣后的图片
    async getGenerateImg() {
      if (!this.userInfo.isVip) {
        this.$bus.$emit("vipPopup", {
          this: 1,
          hideCoupon: true,
          closeBtn: () => {
            this.$bus.$emit("closeVipPopup");
          },
        });
        return;
      }
      if (!this.coverImg) return Toast("请先上传一张图片");
      // console.log(this.walletInfo)
      // if (this.freeCount <= 0 && Number(this.aiUndressPrice) > this.balance)
      //   return Toast("免费次数与账户余额不足");
      let req = {
        originPic: this.coverImg,
        coin: Number(this.aiUndressPrice),
      };
      let res = await this.$Api(generateImg, req);
      if (res && res.code == 200) {
        this.$store.dispatch("user/getWallet");
        Toast("上传成功，等待审核");
        this.coverImg = "";
        if (this.freeCount > 0) {
          this.freeCount--;
        }
      } else {
        Toast(res.tip || "上传失败");
      }
    },
    async beforeDelete(file, detail) {
      const newArr = await this.delElbyIndex(this.uploadImages, detail.index);
      this.totalPrice = 0; //showPrice(newArr.length, this.aiUndressPrice);
      this.uploadImages = newArr;
      return true;
    },
    // 根据索引删除数组
    delElbyIndex(arr, index) {
      for (let i = index, len = arr.length - 1; i < len; i++) {
        arr[i] = arr[i + 1];
        arr.length = len;
      }
      return arr;
    },
    // 价格优惠
    showPrice(quantity, price) {
      let totalPrice = 0;
      totalPrice = quantity * price;
      return Math.ceil(totalPrice);
    },
    onSubmit() {
      this.debounce(
        this.type == "video" ? this.getGenerateVideo : this.getGenerateImg,
        500
      );
    },
    debounce(fn, wait) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        fn();
      }, wait);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-swipe__indicators {
  left: 90%;
  .van-swipe__indicator {
    background-color: rgba(148, 214, 218, 0.3);
  }
  .van-swipe__indicator--active {
    background-color: #94d6da !important;
  }
}
/deep/ .van-uploader__preview {
  width: 111px;
  height: 111px;
  border-radius: 8px;
  overflow: hidden;
  margin: unset;
  .van-uploader__preview-image {
    width: unset;
    height: unset;
  }
}
.undress {
  padding: 0;
  margin: 20px 10px;
  .undress_top {
    // width: 100%;
    display: flex;
    .cover {
      /deep/ .van-image__img {
        width: 111px;
        height: 111px;
      }
    }
    /deep/ .van-uploader {
      width: 111px;
      height: 111px;
      .van-uploader__wrapper {
        width: 100%;
        height: 100%;
        border: dashed 1px #999999;
        border-radius: 5px;
        overflow: hidden;
        background: #ebebeb;
      }
      .van-uploader__input-wrapper,
      .uploadBox {
        width: 111px;
        height: 111px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        font-size: 12px;
        color: #999999;
      }
    }
    .precautionsBox {
      h2 {
        font-size: 14px;
        color: #333333;
        margin: 6px 0;
        font-weight: 500;
      }
      margin-left: 20px;
      font-size: 12px;
      line-height: 16.8px;
      color: #999999;
    }
  }
  .aiImg {
    width: 100%;
    height: 260px;
    margin: 20px 0;
    .advSwiper {
      height: 260px;
      transform: translateZ(0px);
      position: relative;
      :deep(.van-swipe-item) {
        height: 260px !important;
      }
    }
  }
  .bottom {
    text-align: center;
    p {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 400;
      span {
        color: #b09fd9;
      }
    }
  }
  .submit {
    // position: fixed;
    margin: 30px auto 0;
    width: 333px;
    height: 49px;
    color: #b09fd9;
    font-weight: 500;
    background: #fff;
    border-radius: 30px;
    box-shadow: -5px -5px 20px 0px #fff,
      5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    font-family: "Dream Han Sans TC";
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
  }
  .sizeBox {
    height: 170px;
  }
}
</style>
